import React from 'react'
import { WidgetHeaderStyled, TabStyled, TabStyledSelect, Tabs, BoxIcons } from './widgetHeaderMulti.style'
import { CustomFilterButton, CustomReplayButton, WidgetHeaderDivider, IconButton } from '../WidgetHeader/WidgetHeader.style'

function WidgetHeaderMulti(props) {
  const { titles, has_reload, refreshData, setBlotter, setIsOpenDrawerFilter, isOpenDrawerFilter } = props
  const [value, setValue] = React.useState(titles[0])

  const handleChange = (event, newValue) => {
    setBlotter(newValue)
    setValue(newValue)
  }

  const handelFilterIcon = () => {
    setIsOpenDrawerFilter(!isOpenDrawerFilter)
  }

  return (
    <>
      <WidgetHeaderStyled>
        <Tabs value={value} onChange={handleChange}>
          {titles.map((title, index) => (title === value ? <TabStyledSelect key={index} label={title} value={title} /> : <TabStyled key={index} label={title} value={title} />))}
        </Tabs>
        <BoxIcons>
          {has_reload && (
            <IconButton onClick={refreshData}>
              <CustomReplayButton />
            </IconButton>
          )}
          {titles[0] === value && (
            <IconButton onClick={handelFilterIcon}>
              <CustomFilterButton isopendrawerfilter={String(isOpenDrawerFilter)} />
            </IconButton>
          )}
        </BoxIcons>
      </WidgetHeaderStyled>
      <WidgetHeaderDivider />
    </>
  )
}

export default WidgetHeaderMulti
