import React, { useState } from 'react'
import { useEffect } from 'react'
import { ENDPOINTS, REST_GATEWAY_URL, WIDGETS, WIDGETS_ID } from '../../utils/constants'
import CustomTable from '../Table/SimpleTable'
import { IconButton, Tooltip } from '@mui/material'
import useAxios from '../../hooks/useAxios'
import { StyledTableCell, StyledType, StyledIcon } from '../Table/SimpleTable.style'
import { useTheme } from '@emotion/react'
import LoadingOverlay from '../NotificationOverlays/LoadingOverlay'
import ShortText from '../SharedComponents/ShortText/ShortText'
import CopyText from '../SharedComponents/CopyText/CopyText'
import DateTooltip from '../SharedComponents/DateTooltip/DateTooltip'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/slices/Auth/authSlice'
import { handelNotifications } from '../../redux/slices/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
import { numberWithCommas } from '../../utils/helperFunctions'
import DrawerFilters from '../DrawerFilters/DrawerFilters'
import DrawerFilterTradeBlotter from '../DrawerFilters/children/DrawerFilterTradeBlotter/DrawerFilterTradeBlotter'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

function TradesBlotter({ isOpenDrawerFilter }) {
  const theme = useTheme()
  const [from, setFrom] = useState(dayjs.utc().startOf('month').valueOf());
  const [params, setParams] = useState({
    count: 20,
    page: 1,
    filter: 'executed_at>=' + from.toString(),
  })
  const [scrollEffect, setScrollEffect] = useState(true)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const columns = [
    'Id',
    'Order Id',
    'Status',
    'Instrument',
    'Order Type',
    'Time In Force',
    'Side',
    'Quantity',
    'Executed Price',
    'User',
    'Channel',
    'Executed At',
  ]

  const resetPagination = () => {
    setParams({ ...params, count: 20, page: 1 })
  }

  const [response, setResponse] = useAxios(
    {
      endPoint: ENDPOINTS.TRADE,
      method: 'GET',
      params: params,
      widget: WIDGETS.TRADES_BLOTTER,
      setLoading,
    },
    { resetState: [resetPagination] }
  )

  const onScrollHandel = async (event) => {
    const e = event.target
    if (Math.abs(e.scrollHeight - e.scrollTop - e.clientHeight) < 2 && response.data.pageinfos.total_count > response.data.data.length && scrollEffect) {
      const url = `${REST_GATEWAY_URL}${ENDPOINTS.TRADE}`
      try {
        setScrollEffect(false)
        const apiResult = await axios.get(url, { params: { ...params, page: ++params.page } })
        apiResult.data.data = response.data.data.concat(apiResult.data.data)
        setResponse({ status: apiResult.status, data: apiResult.data })
        setParams({ ...params, page: ++params.page })
        if (apiResult.data.data.length !== apiResult.data.pageinfos.total_count) {
          setScrollEffect(true)
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          navigate('/login')
          dispatch(logout())
          dispatch(
            handelNotifications({
              id: Date.now(),
              message: `Please Login`,
              type: 'error',
            })
          )
          return
        }
        dispatch(
          handelNotifications({
            id: Date.now(),
            message: `Failed to get ${WIDGETS.TRADES_BLOTTER.replace('_', ' ')} data`,
            type: 'error',
          })
        )
      }
    }
  }

  const tradeBlotterAdapter = () => {
    if (response.status === 200) {
      const data = response.data.data.map((trade) => ({
        id: (
          <StyledTableCell component="td" scope="cell" align="center">
            <CopyText text={trade.id} />
          </StyledTableCell>
        ),
        order_id: (
          <StyledTableCell component="td" scope="cell" align="center">
            <CopyText text={trade.order_id} />
          </StyledTableCell>
        ),
        status: (
          <StyledTableCell component="td" scope="cell" align="center">
            <Tooltip title="filled" placement="top">
              <IconButton>
                <StyledIcon status="filled" />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        ),
        instrument: (
          <StyledTableCell component="td" scope="cell" align="center">
            {trade.symbol}
          </StyledTableCell>
        ),
        order_type: (
          <StyledTableCell component="td" scope="cell" align="center" style={{ padding: 0 }}>
            <StyledType order_type={trade.order_type}>{trade.order_type}</StyledType>
          </StyledTableCell>
        ),
        time_in_force: (
          <StyledTableCell component="td" scope="cell" align="center">
            {trade.tif}
          </StyledTableCell>
        ),
        side:
          trade.side === 'buy' ? (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.green.green1}>
              {trade.side.toUpperCase()}
            </StyledTableCell>
          ) : (
            <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.red.red1}>
              {trade.side.toUpperCase()}
            </StyledTableCell>
          ),
        quantity: (
          <StyledTableCell component="td" scope="cell" align="center">
            <ShortText text={trade.quantity} char={'*'} />
          </StyledTableCell>
        ),
        executed_price: (
          <StyledTableCell component="td" scope="cell" align="center" style={{ minWidth: '120px' }}>
            <ShortText text={numberWithCommas(trade.executed_price)} char={'*'} />
          </StyledTableCell>
        ),
        user: (
          <StyledTableCell component="td" scope="cell" align="center" style={{ fontWeight: '550' }}>
            {trade.trader.replace(new RegExp('@(.*)'), '')}
          </StyledTableCell>
        ),
        channel: (
          <StyledTableCell component="td" scope="cell" align="center">
            {trade.channel}
          </StyledTableCell>
        ),
        executed_at: (
          <StyledTableCell component="td" scope="cell" align="center" color={theme.palette.grey.grey1}>
            <DateTooltip date={trade.executed_at} />
          </StyledTableCell>
        ),
      }))
      return { data }
    } else {
      return { data: [] }
    }
  }

  let tradeBlotterData = []
  if (response?.data) {
    tradeBlotterData = tradeBlotterAdapter().data
  }

  useEffect(() => {
    setParams({
      count: 20,
      page: 1,
      filter: 'executed_at>=' + from.toString()
    })
  }, [from])

  return (
    <>
      {loading === false ? (
        <>
          <DrawerFilters isOpenDrawerFilter={isOpenDrawerFilter}>
            <DrawerFilterTradeBlotter setFrom={setFrom} from={from} />
          </DrawerFilters>
          <CustomTable rows={tradeBlotterData} columns={columns} id={WIDGETS_ID.TRADES_BLOTTER} isopendrawerfilter={String(isOpenDrawerFilter)} onScrollHandel={onScrollHandel} />
        </>
      ) : (
        <LoadingOverlay height={'calc(100% - 34px)'} />
      )}
    </>
  )
}

export default TradesBlotter
